@import "~antd/dist/antd.less";  
@primary-color : #5143ff;
@btn-primary-bg : #5143ff;
@info-color : #5143ff;

@font-face {
    font-family: CustomFont;
    src: url(./SourceHanSansCN-Medium.otf);
}

body {
font-family: CustomFont, Arial, sans-serif;
}

.z-select{
    .ant-select-selector{
        border-radius: 8px !important;
    }
}
#root{
    width: 100%;
    height: 100%;
}

.ant-menu.ant-menu-inline-collapsed{
    width : 50px;
}

.ant-menu-item{
    margin-top : 0px !important;
    margin-bottom: 0px !important;
    height: 44px !important;
}

.ant-layout-header{
    height: 56px;
}
.reset-padding {
    .ant-popover-inner-content {
        padding: 0 6px;
      }
}
// .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub{
//     background: white !important;
//     color: #666666 !important;
// }

// .ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a{
//     color :  #666666 !important;
// }

// .ant-menu-dark .ant-menu-item-selected > a, .ant-menu-dark .ant-menu-item-selected > span > a, .ant-menu-dark .ant-menu-item-selected > a:hover, .ant-menu-dark .ant-menu-item-selected > span > a:hover{
//     color :  white !important;
// }

// .ant-menu-inline.ant-menu-root .ant-menu-item, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title{
//     transition: border-color 0.1s, background 0.1s, padding 0.1s, width 0.1s, margin 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
// }

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: #5143ff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-right: 3px;
    width: calc(100% - 3px);
}
.adm-tab-bar-item-active {
    color: #5143ff;
}

/*美化滚动条*/
::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e9e9e9;
    opacity: .8;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #e9e9e9;
    opacity: .8;
  }
  
.round-modal{
    .ant-modal-header{
        padding : 0;
    }
    .ant-modal-content{
        overflow : hidden;
        border-radius: 8px;
    }
    .ant-form-item-label{
        font-weight: 200;
        letter-spacing: 2px;
    }
} 

.ant-tree-title{
    font-weight: 200;
    font-size: 12px;
}

.room_tree{
    .ant-tree-node-selected{
        background-color: #5143ff !important;
        
        .ant-tree-title{
            color : white;
            font-weight: 400;
        }
    }
}

.user_tree{
    .ant-tree-node-selected{
        background-color: #fff !important;
		color: #5143ff !important;
        
        .ant-tree-title{
            // color : white;
            font-weight: 400;
        }
    }
}
.ant-table-thead > tr > th {
    background: #EFEEFF !important;
}


.zh_form{
    @primary-color : red;
    .ant-table-cell{
        border-bottom: 0px;
        padding : 5px 8px !important;
        font-weight: 200 !important;

        .ant-tag{
            width: 50px;
            border-radius: 10px;
            text-align: center;
        }
    }
    .ant-table-pagination.ant-pagination{
        margin : 5px 16px !important;
        zoom : .95;
        font-weight: 200 !important;
    }
}

.seleted-rooms{
    display: flex;
    height: 36px;
    align-items: center;
    background-color: #5143ff1f;
    padding : 5px 15px;
    margin: 3px 0px;
    border-radius: 8px;
    font-weight: 200;
    font-size: 12px;
    color: #868585;
}

.seleted-users{
    display: flex;
    height: 36px;
    align-items: center;
    background-color: #13c2c21f;
    padding : 5px 15px;
    margin: 3px 0px;
    border-radius: 8px;
    font-weight: 200;
    font-size: 12px;
    color: #868585;
}


.zh-nt{
    border-radius: 14px !important;
    box-shadow: 0 3px 20px 0px rgb(0 0 0 / 12%) !important;
    background-color: #ffffffdd !important;
    .anticon.ant-notification-notice-icon-success{
        color : #5143ff;
    }
    .ant-notification-notice-with-icon .ant-notification-notice-description{
        font-size: 12px;
        font-weight: 200;
    }
    .ant-notification-notice-with-icon .ant-notification-notice-message{
        font-size: 14px !important;
        color: #868585;
    }
}

.zh-table{
    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
        background: transparent;
    }

    .ant-table-small .ant-table-thead > tr > th{
        // background: #5143ff;
        // font-size: 12px;
        color: #9b9b9b;
    }
    .ant-table-cell{
        text-align: center;
    }

    // .ant-table-cell-scrollbar{
    //     background: #e9e9e9 !important;
    // }
}

#zh-booking-form{
    .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button{
        border-radius: 0 8px 8px 0;
    }

    .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child{
        border-radius: 8px 0 0 8px;
    }
}



.room-table-content::-webkit-scrollbar{
    display: none;
    position : absolute;
}
.room-table-content {
    scrollbar-width: none
}


.daily-table-conf-active::before{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    border-radius: 3px;
    opacity: 0;
    -webkit-animation: ant-progress-active 2.4s cubic-bezier(.23,1,.32,1) infinite;
    animation: ant-progress-active 2.4s cubic-bezier(.23,1,.32,1) infinite;
    content: "";
}


.today-text{
    color : white;
    background-color: #5143ff;
}

.watch-table-item {
    flex-direction: column;
    .name, .icon {
        padding-left: 20px;
        width: 100%;
        text-align: left;
    }
    img {
        width: 25px;
        margin-right: 5px;
    }
}
.watch-table-romm-item{
    cursor : pointer; 
    color : #5143ffd4;
}

.watch-table-romm-item-selected{
    cursor : pointer; 
    color : #5143ff;
    background : linear-gradient(to right, #87cefa2b, #d4d3f038, #add8e63d);
}

.watch-table-romm-item:hover{
    color: #5143ffa1;
}


#add-user-search{
    .ant-input-search-button{
        background-color: #5143ff;
        border: #5143ff;
    }
}

.conf-square-1:hover{
    // background: #8cb0ff !important;
    z-index: 100;
    // box-shadow : none !important;
}

.conf-square-0:hover{
    // background: #1be8e8 !important;
    z-index: 100;
    
}

.conf-square-other:hover{
    // background: #c6c3c3 !important;
    z-index: 100;
    
}

#booking-panel::-webkit-scrollbar{
    width: 0px;
    height: 8px;
}

#booking-panel:hover::-webkit-scrollbar{
    width: 5px;
    height: 8px;
}

#ulink-data-dash{
    .ant-badge-status-dot{
      width : 8px;
      height: 8px;
    }
}

.zh-desc{
    .ant-descriptions-row > th, .ant-descriptions-row > td{
        padding-bottom: 8px;
    }
}

.live-little-show{
    height: 225px;
    transition: all 0.4s ease-in;
    // cursor: pointer;
    // filter: blur(5px);
}


.live-little-hide{
    height: 0px;
    transition: all 0.4s ease-out;
}

.live-little-hover{
    cursor: pointer;
    filter: blur(3px);
    // width : 100%;
    // height : 100%;
    // position : absolute;
    // transform: scale(1.2);
    // .sub-video{
        
    // }
    .sub-swap{
        display : none;
    }
}

.seleted-tab{
    border-bottom: 2px solid white;
}

.chat-text {
    font-size: 12px;
    padding: 4px 8px;
    word-break: break-all;
    width: 100%;
}


#mainView::-webkit-media-controls-fullscreen-button {
    display: none;
}
//播放按钮
#mainView::-webkit-media-controls-play-button {
    display: none;
}
//进度条
#mainView::-webkit-media-controls-timeline {
    display: none;
}
//观看的当前时间
#mainView::-webkit-media-controls-current-time-display{
    display: none;           
}
//剩余时间
#mainView::-webkit-media-controls-time-remaining-display {
    display: none;           
}

.live-reload:hover{
    opacity: .5;
}

#add-users-tabs{
    .ant-tabs-ink-bar{
        color :#5143ff;
        background : #5143ff;
    }
}

.ant-popover-message-title{
    padding-left: 0px;
}

#my-contact-groups {
    .ant-tree-switcher{
        width: 0px;
    }
}